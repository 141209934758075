exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1cDuso1wBQDBT3ikxofLyT {\n\n}\n\n._3DQn0uuoUP5b3XuI8w6uOV {\n  \n}\n\n._2VsH8ZwuE0L0X4Th93xbJf {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._9ceSvg5PuOwGv-in4ngpP {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/RetentionPayments/Components/Header/Component.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;;CAEC;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;CACd","file":"Component.css","sourcesContent":[".wrapper {\n\n}\n\n.breadcrumbs {\n  \n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.title {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1cDuso1wBQDBT3ikxofLyT",
	"breadcrumbs": "_3DQn0uuoUP5b3XuI8w6uOV",
	"header": "_2VsH8ZwuE0L0X4Th93xbJf",
	"title": "_9ceSvg5PuOwGv-in4ngpP"
};