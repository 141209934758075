exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".IPD4z82H0spc1jrbx5HhH {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  border: 1px solid #CCC;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n._1c2A0FhsywPlpFdls8_C7X {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.FPE-JDJ31cIacK5UPZGxb {\n  color: #1990FF;\n  cursor: pointer;\n}\n\n.FPE-JDJ31cIacK5UPZGxb:hover {\n  text-decoration: underline;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/EstimateView/Components/Items/Components/GroupPlaceholder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,oBAAoB;EACpB,wBAAwB;CACzB;;AAED;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE,eAAe;EACf,gBAAgB;CACjB;;AAED;EACE,2BAA2B;CAC5B","file":"GroupPlaceholder.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  border: 1px solid #CCC;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.message {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.addLink {\n  color: #1990FF;\n  cursor: pointer;\n}\n\n.addLink:hover {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "IPD4z82H0spc1jrbx5HhH",
	"message": "_1c2A0FhsywPlpFdls8_C7X",
	"addLink": "FPE-JDJ31cIacK5UPZGxb"
};