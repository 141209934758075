exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1DgaKdCnBSGI9z6sgzOXCK {\n  display: flex;\n  align-items: center;\n}\n\n._3R7GLZGdz2ANPWQhSEwR6L {\n  flex: 1;\n}\n\n._3R7GLZGdz2ANPWQhSEwR6L h1 {\n  margin: 0px;\n}\n\n._1DokqLL90R0_MIBoBEk3DL {\n}\n\n._K-UxAQgl6TOENRC-I-Gs {\n  min-width: 80px;\n  text-align: center;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/Subbies/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,YAAY;CACb;;AAED;CACC;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.left {\n  flex: 1;\n}\n\n.left h1 {\n  margin: 0px;\n}\n\n.right {\n}\n\n.radioButton {\n  min-width: 80px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1DgaKdCnBSGI9z6sgzOXCK",
	"left": "_3R7GLZGdz2ANPWQhSEwR6L",
	"right": "_1DokqLL90R0_MIBoBEk3DL",
	"radioButton": "_K-UxAQgl6TOENRC-I-Gs"
};