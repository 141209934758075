exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1AhpthyjrxDMzO-TaDtH6p {\n\n}\n\n.kMmQb63Y8w8UlZh9gubef {\n  width: 32px;\n  height: 32px;\n  line-height: 26px;\n  border-radius: 16px;\n  border: 3px solid white;\n  margin-right: -8px;\n  text-align: center;\n  font-size: 10px;\n  font-weight: 500;\n  display: inline-block;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Components/ServiceTypeIcons.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,oBAAoB;EACpB,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;CACvB","file":"ServiceTypeIcons.css","sourcesContent":[".wrapper {\n\n}\n\n.icon {\n  width: 32px;\n  height: 32px;\n  line-height: 26px;\n  border-radius: 16px;\n  border: 3px solid white;\n  margin-right: -8px;\n  text-align: center;\n  font-size: 10px;\n  font-weight: 500;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1AhpthyjrxDMzO-TaDtH6p",
	"icon": "kMmQb63Y8w8UlZh9gubef"
};