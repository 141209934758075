import React from 'react'
import {Table, Tag} from 'antd'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import styles from './Table.css'

type Props = {
    settings: [],
    fetching: boolean,
    pagination: {},
    onChangeTable: () => void,
    onClickRow: () => void,
}


function DisplayValue(props) {

    let v;
    if (props.value != undefined)
        v = props.value;
    else v = "";
    
    if (props.processor == "FileUpload" && v != "")
        return DisplayImage("/settings/files/" + v);
    else 
    {
	if (v.length > 100)
		return v.substr(0,80) + '...';
	else	return v;
    }
}

function DisplayImage(logo) {
    return <img src={logo}/>
}

export default class extends React.PureComponent<Props> {

    render() {

        const {
            settings,
            fetching,
            pagination,
            onChangeTable,
            onClickRow,
        } = this.props

        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sorter: false,
                render: (text, record) => record.title,
            },
            {
                title: 'Type',
                dataIndex: 'type',
                sorter: false,
                render: (text, record) => (
                    <div>
                        {record.type === 'user' && <Tag color='green'>User</Tag>}
                        {record.type === 'team' && <Tag color='gold'>Team</Tag>}
                        {record.type === 'system' && <Tag color='blue'>System</Tag>}
                    </div>
                ),
            },
            {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
                sorter: false,
                render: (text, record) => record.group,
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                sorter: false,
                render: (text, record) => (
                    <DisplayValue value={record.value} processor={record.processor}/>
                ),
            },
        ]

        return (
            <Table
                columns={columns}
                dataSource={settings}
                rowKey='id'
                onChange={onChangeTable}
                bordered
                loading={fetching}
                className={styles.table}
                onRow={(record) => ({
                    onClick: () => onClickRow(record.id),
                })}
                style={{ whiteSpace: 'pre'}}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
            />
        )

    }

}


