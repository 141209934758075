exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Z3dhM-UGE9Ty8qInWQSA5 {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._2TS5r5hmjSbMec_FMXXmDr {\n  flex: 1;\n  display: flex;\n}\n\n.oCLXQlJstLLBCVqyKqLua {\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubProject/Components/SubOrders/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;CACC","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "Z3dhM-UGE9Ty8qInWQSA5",
	"left": "_2TS5r5hmjSbMec_FMXXmDr",
	"right": "oCLXQlJstLLBCVqyKqLua"
};