exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GS4aG-ZxF92Hh7R-a57yM {\n  width: 100%;\n  height: 60px;\n  border-bottom: 1px solid #E8E8E8;\n  display: flex;\n  align-items: center;\n  padding: 15px;\n}\n\n.sRgsN788ok1Yftt3r5VsZ {\n  display: flex;\n  align-items: center;\n  flex: 1;\n}\n\n._20ue1aoB4CsDBRw0L3P8t8 {\n  font-size: 24px;\n  margin-right: 10px;\n}\n\n._1Z2Z08-RIcl4SMkz9tMVp5 {\n  font-size: 24px;\n  cursor: pointer;\n}\n\n._3LJf-qYgN_8pO2scGKm-V1 {\n  color: #999;\n  font-weight: 500;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n._3LJf-qYgN_8pO2scGKm-V1:hover {\n  color: #1990FF;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Modules/FileManager/Components/Toolbar/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,iCAAiC;EACjC,cAAc;EACd,oBAAoB;EACpB,cAAc;CACf;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,QAAQ;CACT;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB;;AAED;EACE,gBAAgB;EAChB,gBAAgB;CACjB;;AAED;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;CAChB","file":"styles.css","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 60px;\n  border-bottom: 1px solid #E8E8E8;\n  display: flex;\n  align-items: center;\n  padding: 15px;\n}\n\n.path {\n  display: flex;\n  align-items: center;\n  flex: 1;\n}\n\n.pathIcon {\n  font-size: 24px;\n  margin-right: 10px;\n}\n\n.actionIcon {\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.link {\n  color: #999;\n  font-weight: 500;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.link:hover {\n  color: #1990FF;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "GS4aG-ZxF92Hh7R-a57yM",
	"path": "sRgsN788ok1Yftt3r5VsZ",
	"pathIcon": "_20ue1aoB4CsDBRw0L3P8t8",
	"actionIcon": "_1Z2Z08-RIcl4SMkz9tMVp5",
	"link": "_3LJf-qYgN_8pO2scGKm-V1"
};