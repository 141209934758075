exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._8HKSYSkI8Gxz_B9kt0367 { \n  display: flex;\n  margin-bottom: 15px;\n}\n\n._3U15hPqgI_9_b08MGaOvm1 {\n  min-width: 70px;\n  text-align: center;\n  font-size: 12px;\n} ", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/RetentionPayments/Components/Filters/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;CACjB","file":"Component.css","sourcesContent":[".wrapper { \n  display: flex;\n  margin-bottom: 15px;\n}\n\n.radio {\n  min-width: 70px;\n  text-align: center;\n  font-size: 12px;\n} "],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_8HKSYSkI8Gxz_B9kt0367",
	"radio": "_3U15hPqgI_9_b08MGaOvm1"
};