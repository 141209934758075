exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1zW0U7xVkdSW7Wr2OIYtWZ {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n._3Y0ryeTQ9wqRUTJNUNcQNV {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n._1fhEKrq47WCIsq37wZaXAN {\n  color: #1990FF;\n  cursor: pointer;\n}\n\n._1fhEKrq47WCIsq37wZaXAN:hover {\n  text-decoration: underline;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/OrderView/Components/Caveats/Components/Placeholder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;EACpB,wBAAwB;CACzB;;AAED;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE,eAAe;EACf,gBAAgB;CACjB;;AAED;EACE,2BAA2B;CAC5B","file":"Placeholder.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.message {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.addLink {\n  color: #1990FF;\n  cursor: pointer;\n}\n\n.addLink:hover {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1zW0U7xVkdSW7Wr2OIYtWZ",
	"message": "_3Y0ryeTQ9wqRUTJNUNcQNV",
	"addLink": "_1fhEKrq47WCIsq37wZaXAN"
};