exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3mT8s3XEjzsxc90L-28zsZ {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n.JzSdfG-3Ou7b0ASUY610p {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n._2mJHJx7zmvwlCrnUy2y3d0 {\n  color: #1990FF;\n  cursor: pointer;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubOrder/Components/Items/Components/SubOrderPlaceholder/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;CACpB;;AAED;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE,eAAe;EACf,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n.message {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.addLink {\n  color: #1990FF;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3mT8s3XEjzsxc90L-28zsZ",
	"message": "JzSdfG-3Ou7b0ASUY610p",
	"addLink": "_2mJHJx7zmvwlCrnUy2y3d0"
};