exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2n4nH67qAncgBDCKKCSoC7 {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._354CCwvFzzscvbcZgzQs_O {\n  flex: 1;\n  display: flex;\n}\n\n._1r6WJB8E9yZ99tjMnRm68K {\n  display: flex;\n}\n\n.YxY_QGSK7XC6tajlVN-Yo {\n  width: 280px;\n}\n\n._3qnJtzXOn3n4YTt306FBqs {\n  margin-bottom: 12px;\n}\n\n._3qnJtzXOn3n4YTt306FBqs:last-child {\n  margin-bottom: 4px;\n}\n\n._2rwDzlNvtdfEEsvszTNst_ {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/Subbies/Components/SubInvoices/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;EACE,cAAc;CACf;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n  display: flex;\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "_2n4nH67qAncgBDCKKCSoC7",
	"left": "_354CCwvFzzscvbcZgzQs_O",
	"right": "_1r6WJB8E9yZ99tjMnRm68K",
	"filters": "YxY_QGSK7XC6tajlVN-Yo",
	"box": "_3qnJtzXOn3n4YTt306FBqs",
	"label": "_2rwDzlNvtdfEEsvszTNst_"
};