exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2weMl4Dmv74gWYrfeHMLEr tbody tr {\n  cursor: pointer;\n}\n\n._2ibR1x4y41PzqJcShSVoCO {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n._3wEXKyqSO9l7U1WKgSyO4x {\n  display: flex;\n  align-items: flex-start;\n  padding-top: 0px !important;\n  padding-bottom: 0px !important;\n}\n\n._3wEXKyqSO9l7U1WKgSyO4x .ant-list-item-content {\n  flex: none;\n}\n\n._2MWluTtywwnuDhmTVPkEh0 {\n  text-align: right !important;\n}\n\n._1uCsul6nv-fYH1_EasEA7a:hover {\n  text-decoration: underline;\n}\n\n._32CWUK1169plar_LVeQtD4:hover {\n  text-decoration: underline;\n}\n\n._3yodzXF3py8IsA0Feb07yj {\n  position: absolute;\n  top: 0;\n  left: 0;\n  padding: 10px;\n  opacity: 0;\n}\n\n.y07hrRI5Gwp5WEnElDQmD:hover ._3yodzXF3py8IsA0Feb07yj {\n  opacity: 1;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/Timesheets/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB;;AAED;EACE,cAAc;EACd,+BAA+B;EAC/B,oBAAoB;CACrB;;AAED;EACE,cAAc;EACd,wBAAwB;EACxB,4BAA4B;EAC5B,+BAA+B;CAChC;;AAED;EACE,WAAW;CACZ;;AAED;EACE,6BAA6B;CAC9B;;AAED;EACE,2BAA2B;CAC5B;;AAED;EACE,2BAA2B;CAC5B;;AAED;EACE,mBAAmB;EACnB,OAAO;EACP,QAAQ;EACR,cAAc;EACd,WAAW;CACZ;;AAED;EACE,WAAW;CACZ","file":"Table.css","sourcesContent":[".table tbody tr {\n  cursor: pointer;\n}\n\n.subcontractor {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.listItem {\n  display: flex;\n  align-items: flex-start;\n  padding-top: 0px !important;\n  padding-bottom: 0px !important;\n}\n\n.listItem :global(.ant-list-item-content) {\n  flex: none;\n}\n\n.numericColumn {\n  text-align: right !important;\n}\n\n.startDateTime:hover {\n  text-decoration: underline;\n}\n\n.endDateTime:hover {\n  text-decoration: underline;\n}\n\n.cellButtons {\n  position: absolute;\n  top: 0;\n  left: 0;\n  padding: 10px;\n  opacity: 0;\n}\n\n.hoursColumn:hover .cellButtons {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_2weMl4Dmv74gWYrfeHMLEr",
	"subcontractor": "_2ibR1x4y41PzqJcShSVoCO",
	"listItem": "_3wEXKyqSO9l7U1WKgSyO4x",
	"numericColumn": "_2MWluTtywwnuDhmTVPkEh0",
	"startDateTime": "_1uCsul6nv-fYH1_EasEA7a",
	"endDateTime": "_32CWUK1169plar_LVeQtD4",
	"cellButtons": "_3yodzXF3py8IsA0Feb07yj",
	"hoursColumn": "y07hrRI5Gwp5WEnElDQmD"
};