exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2nrRsPDzjrGcCpjavw-6vq {\n  display: block !important;\n  margin-top: 10px !important;\n  margin-bottom: 10px !important;\n}\n\n._1UtepWw9JuTgBIprOoUjaB {\n  display: inline-block !important;\n  vertical-align: top !important;\n  line-height: 20px !important;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Modals/CreateInvoiceForValuation/Components/Fields/Component.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,4BAA4B;EAC5B,+BAA+B;CAChC;;AAED;EACE,iCAAiC;EACjC,+BAA+B;EAC/B,6BAA6B;CAC9B","file":"Component.css","sourcesContent":[".radio {\n  display: block !important;\n  margin-top: 10px !important;\n  margin-bottom: 10px !important;\n}\n\n.radioText {\n  display: inline-block !important;\n  vertical-align: top !important;\n  line-height: 20px !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"radio": "_2nrRsPDzjrGcCpjavw-6vq",
	"radioText": "_1UtepWw9JuTgBIprOoUjaB"
};