exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".FVv-lsLAIJxbcVhOJWrrH {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._39xXpuNVH6Vq-64xrK457G {\n  flex: 1;\n}\n\n.HruLSJ4M70xwX5-a6eUTn {\n  margin: 0px;\n}\n\n._2txA_SGDO5DBbXN0DzD81q {\n  color: red;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/ProjectView/Components/Header.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,YAAY;CACb;;AAED;EACE,WAAW;CACZ","file":"Header.css","sourcesContent":[".stack {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.heading {\n  flex: 1;\n}\n\n.header {\n  margin: 0px;\n}\n\n.uplift {\n  color: red;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"stack": "FVv-lsLAIJxbcVhOJWrrH",
	"heading": "_39xXpuNVH6Vq-64xrK457G",
	"header": "HruLSJ4M70xwX5-a6eUTn",
	"uplift": "_2txA_SGDO5DBbXN0DzD81q"
};