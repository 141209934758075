exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2ewTvURI1Qa82uvYURC_yB {\n  width: 100%;\n  height: 60px;\n  border-bottom: 1px solid #E8E8E8;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 15px;\n}\n\n._2pPKXkrKUSBFpMBvGYZrDR {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex: 1;\n}\n\n._1aRpXDY_Ka-z_Q1HntktCX {\n  font-size: 24px;\n  margin-right: 10px;  \n}\n\n._1Ghb0Cc7c18ys1YNLTJADD {\n  \n}\n\n.bd7cAUiQ4SgQ9yutEFkM4 {\n  font-size: 24px;\n  cursor: pointer;\n}\n\n._1247ROK_ZIO_A0Y1mmDrzf {\n  cursor: pointer;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n._1247ROK_ZIO_A0Y1mmDrzf:hover {\n  color: #1990FF;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/ProjectView/Components/FileManager/Components/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,iCAAiC;EACjC,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;CACf;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,QAAQ;CACT;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB;;AAED;;CAEC;;AAED;EACE,gBAAgB;EAChB,gBAAgB;CACjB;;AAED;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;CAChB","file":"Toolbar.css","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 60px;\n  border-bottom: 1px solid #E8E8E8;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 15px;\n}\n\n.path {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex: 1;\n}\n\n.pathIcon {\n  font-size: 24px;\n  margin-right: 10px;  \n}\n\n.breadcrumbs {\n  \n}\n\n.actionIcon {\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.link {\n  cursor: pointer;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.link:hover {\n  color: #1990FF;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2ewTvURI1Qa82uvYURC_yB",
	"path": "_2pPKXkrKUSBFpMBvGYZrDR",
	"pathIcon": "_1aRpXDY_Ka-z_Q1HntktCX",
	"breadcrumbs": "_1Ghb0Cc7c18ys1YNLTJADD",
	"actionIcon": "bd7cAUiQ4SgQ9yutEFkM4",
	"link": "_1247ROK_ZIO_A0Y1mmDrzf"
};