exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1zr823kDiNGfpWDa3xfo2G {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-top: 25px;\n  border-top: 1px solid #DDD;\n}\n\n._21J4FcxaurS7LV-cyg7-iY {\n  margin-right: 40px;\n}\n\n._2rZ04a1xgYRaYXWU7LVO78 {\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}\n\n._3_hu92Y_DM6oKqFcphdvPO {\n  font-size: 15px;\n  font-weight: 500;\n  color: #444;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/Invoices/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,kBAAkB;EAClB,2BAA2B;CAC5B;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-top: 25px;\n  border-top: 1px solid #DDD;\n}\n\n.total {\n  margin-right: 40px;\n}\n\n.label {\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}\n\n.value {\n  font-size: 15px;\n  font-weight: 500;\n  color: #444;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_1zr823kDiNGfpWDa3xfo2G",
	"total": "_21J4FcxaurS7LV-cyg7-iY",
	"label": "_2rZ04a1xgYRaYXWU7LVO78",
	"value": "_3_hu92Y_DM6oKqFcphdvPO"
};