exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3ZipzSNhZf358RB31-Esx9 {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #DDD;\n  margin-left: 30px;\n}\n\n.RBwRCxFY56N6V8PtgaX6X {\n  flex: 1;\n  font-size: 16px;\n  font-weight: 600;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubValuation/Components/Items/Components/SubOrderFooter/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,8BAA8B;EAC9B,kBAAkB;CACnB;;AAED;EACE,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #DDD;\n  margin-left: 30px;\n}\n\n.total {\n  flex: 1;\n  font-size: 16px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3ZipzSNhZf358RB31-Esx9",
	"total": "RBwRCxFY56N6V8PtgaX6X"
};