exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3Fl8bR4HsgYlo7cM3y4vVL {\n  width: 250px;\n  height: 100%;\n  overflow: scroll;\n}\n\n._3Wxj00buT5StfDT35Ne7c_ {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\n.msEKuJWfScgCuHWaHIuvA {\n  font-size: 20px;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Modules/FileManager/Components/Menu/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;CAClB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB;;AAED;EACE,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".wrapper {\n  width: 250px;\n  height: 100%;\n  overflow: scroll;\n}\n\n.menuItem {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\n.folderIcon {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3Fl8bR4HsgYlo7cM3y4vVL",
	"menuItem": "_3Wxj00buT5StfDT35Ne7c_",
	"folderIcon": "msEKuJWfScgCuHWaHIuvA"
};