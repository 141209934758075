exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._39vee3qxr4aaZC2qGN0RXH {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n._1p1B_0xbF-tFYQRmje6BTH {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n._14IiVa2tgQ6yrx-ZjkTTtz {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n._14IiVa2tgQ6yrx-ZjkTTtz:hover {\n  color: #1990FF;\n  opacity: 1;\n}\n\n._2GUGHJt4jW1QkQ4JkazrmF:hover {\n  color: #F5222D;\n}\n\n._3h6i72JiSdCzWLSBl5hvQ5 {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/OrderView/Components/Caveats/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;EACf,aAAa;CACd;;AAED;EACE,eAAe;EACf,WAAW;CACZ;;AAED;EACE,eAAe;CAChB;;AAED;EACE,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;CAClB","file":"Footer.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.link {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n.addLink:hover {\n  color: #1990FF;\n  opacity: 1;\n}\n\n.removeLink:hover {\n  color: #F5222D;\n}\n\n.total {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_39vee3qxr4aaZC2qGN0RXH",
	"link": "_1p1B_0xbF-tFYQRmje6BTH",
	"addLink": "_14IiVa2tgQ6yrx-ZjkTTtz",
	"removeLink": "_2GUGHJt4jW1QkQ4JkazrmF",
	"total": "_3h6i72JiSdCzWLSBl5hvQ5"
};