import React from 'react'
import { Table, Tag } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styles from './Table.css'
import { isPaymentUser } from 'Common';

type Props = {
  users: [],
  fetching: boolean,
  pagination: {},
  onChangeTable: () => void,
  onClickRow: () => void,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      users,
      fetching,
      pagination,
      onChangeTable,
      onClickRow,
    } = this.props



    // for speed - will replace this later
    let seats = '';
    let seatsTotal = 0;
    let advancedTotal = 0;
    let premiumTotal = 0;
    let basicTotal = 0;

    users.forEach(
      function(u)
	{
         if (u.type == 'Advanced')
         {
	        advancedTotal ++;
            seatsTotal = seatsTotal + 45;
         }
         else if (u.type == 'Premium') {

             premiumTotal++;
             seatsTotal = seatsTotal + 65;
         }else if (u.type == 'Basic' || u.type == 'Basic+' || u.type == 'Admin')
         {
             basicTotal ++;
             seatsTotal = seatsTotal + 20;
         }
      }
    )
    seats = 'You have ' + premiumTotal + ' premium users @ £65 per month, ' + advancedTotal + ' advanced users @ £45 per month, and ' + basicTotal + ' basic users @ £20 per month.';
    seatsTotal = 'Your total monthly spend is £' + seatsTotal + '.';


    const columns = [
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        render: (text, record) => record.full_name,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (text, record) => (
          <div>
              {record.type === 'Premium' && <Tag color='grey'>Premium</Tag>}
              {record.type === 'Advanced' && <Tag color='orange'>Advanced</Tag>}
              {record.type === 'Admin' && <Tag color='green'>Admin</Tag>}
            {record.type === 'Basic' && <Tag>Basic</Tag>}
          </div>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (text, record) => record.email,
      },
      {
        title: 'Status',
        dataIndex: 'deactivated_at',
        key: 'deactivated_at',
        sorter: true,
        render: (text, record) => (
          <React.Fragment>
            {record.status === 'Active' && (
              <div style={{ color: 'green' }}>
                <CheckOutlined />
                {` Active`}
              </div>
            )}
            {record.status === 'Deactivated' && (
              <div style={{ color: '#CCCCCC' }}>
                <CloseOutlined />
                {` Deactivated`}
              </div>
            )}
          </React.Fragment>
        ),
      },
    ]

    return (
      <div>
          <p><strong>{seats}</strong><br /><strong>{seatsTotal}</strong></p>
	<Table
        columns={columns}
        dataSource={users}
        rowKey='id'
        onChange={onChangeTable}
        bordered
        loading={fetching}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id),
        })}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          current: pagination.pageNumber,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
       />
      </div>
    )

  }

}
