exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._391nhQKhQ2QgWyyZCpkBIm {\n  cursor: pointer;\n  width: 30px;\n  height: 30px;\n  padding: 5px;\n  font-size: 22px;\n  margin-left: 5px\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/EstimateView/Components/Header/Components/NotesPopover.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,gBAAgB;CACjB","file":"NotesPopover.css","sourcesContent":[".notesButton {\n  cursor: pointer;\n  width: 30px;\n  height: 30px;\n  padding: 5px;\n  font-size: 22px;\n  margin-left: 5px\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"notesButton": "_391nhQKhQ2QgWyyZCpkBIm"
};