exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".IWyEW6TrglSWd05HjlZ0f {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._6IPuaxUgwdO4V0ruqwzZc {\n  flex: 1;\n  display: flex;\n}\n\n.ZMx8dwQNCM2qC61JoOJ0d {\n}\n\n._2C_P11sdNgdbb10uatEMPv {\n  width: 280px;\n}\n\n._1j83dqo4bF3V-Pw9JVl1rP {\n  margin-bottom: 12px;\n}\n\n._1j83dqo4bF3V-Pw9JVl1rP:last-child {\n  margin-bottom: 4px;\n}\n\n._2JTdIB54e-q4RkpKht8M0j {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubOrders/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;CACC;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "IWyEW6TrglSWd05HjlZ0f",
	"left": "_6IPuaxUgwdO4V0ruqwzZc",
	"right": "ZMx8dwQNCM2qC61JoOJ0d",
	"filters": "_2C_P11sdNgdbb10uatEMPv",
	"box": "_1j83dqo4bF3V-Pw9JVl1rP",
	"label": "_2JTdIB54e-q4RkpKht8M0j"
};