import { combineReducers } from "redux";

import Projects from "./Projects/reducer";
import ProjectView from "./ProjectView/reducer";
import Estimates from "./Estimates/reducer";
import EstimateView from "./EstimateView/reducer";
import Orders from "./Orders/reducer";
import OrderView from "./OrderView/reducer";
import Valuations from "./Valuations/reducer";
import ValuationView from "./ValuationView/reducer";
import Invoices from "./Invoices/reducer";
import Clients from "./Clients/reducer";
import ClientView from "./ClientView/reducer";
import Subbies from "./Subbies/reducer";
import SubcontractorView from "./SubcontractorView/reducer";
import Planner from "./Planner/reducer";
import TimesheetPosting from "./TimesheetPosting/reducer";
import Timesheets from "./Timesheets/reducer";
import RetentionPayments from "./RetentionPayments/reducer";
import RamsRecords from "./RamsRecords/reducer";
import Dashboard from "./Dashboard/reducer";
import InvoiceView from "./InvoiceView/reducer";
import ProfitAndLoss from "./ProfitAndLoss/reducer";
import Insights from "./Insights/reducer";
import SubProjects from "./SubProjects/reducer";
import SubProject from "./SubProject/reducer";
import SubOrders from "./SubOrders/reducer";
import SubOrder from "./SubOrder/reducer";
import SubValuations from "./SubValuations/reducer";
import SubValuation from "./SubValuation/reducer";
import SubInvoice from "./SubInvoice/reducer";
import Users from "./Users/reducer";
import Caveats from "./Caveats/reducer";
import ItemDescriptions from "./ItemDescriptions/reducer";
import Settings from "./Settings/reducer";

export default combineReducers({
  Projects,
  ProjectView,
  Estimates,
  EstimateView,
  Orders,
  OrderView,
  Valuations,
  ValuationView,
  Invoices,
  Clients,
  ClientView,
  Subbies,
  SubcontractorView,
  Planner,
  TimesheetPosting,
  Timesheets,
  RetentionPayments,
  RamsRecords,
  Dashboard,
  InvoiceView,
  ProfitAndLoss,
  Insights,
  SubProjects,
  SubProject,
  SubOrders,
  SubOrder,
  SubValuations,
  SubValuation,
  SubInvoice,
  Users,
  Settings,
  Caveats,
  ItemDescriptions
});
