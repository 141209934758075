exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2KhcpVJvoPOyjmx2_06Oq9 {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 30px;\n  margin-right: 65px;\n  border: 1px solid #CCC;\n}\n\n._3lGJiQx_isKNSd3FV86psl {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n._1CxJWaepKMVOh2yhAYlq6v {\n  color: #1990FF;\n  cursor: pointer;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubOrder/Components/Items/Components/SubOrderGroupPlaceholder/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;CACxB;;AAED;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE,eAAe;EACf,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 30px;\n  margin-right: 65px;\n  border: 1px solid #CCC;\n}\n\n.message {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.addLink {\n  color: #1990FF;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2KhcpVJvoPOyjmx2_06Oq9",
	"message": "_3lGJiQx_isKNSd3FV86psl",
	"addLink": "_1CxJWaepKMVOh2yhAYlq6v"
};