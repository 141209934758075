
import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'

export default class extends React.PureComponent {

  static propTypes = {
    filters: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }

  render () {

    const {
      filters,
      onChangeFilter,
    } = this.props

    const typeOptions = [
      { title: 'All', key: ''},
      { title: 'User', key: 'User'},
      { title: 'Team', key: 'Team'},
    ]

    const groupOptions = [
        { title: 'All', key: '' },
        { title: 'Display', key: 'Display'},
        { title: 'Company', key: 'Company'},
        { title: 'Services', key: 'Services'},
        { title: 'Company Address', key: 'Company Address'},
    ]

    return (

      <div class="_filter_bar" >

        <Radio.Group
          value={filters.type}
          onChange={(event) => onChangeFilter('type', event.target.value)}
        >

          {typeOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>

        <div style={{ width: 10 }} />

        <Radio.Group
          value={filters.group}
          onChange={(event) => onChangeFilter('group', event.target.value)}
        >

          {groupOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>



      </div>

    )

  }

}
