exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3cn3gnPRQ9bDgALM0llS4- {\n  height: 100%;\n  padding-top: 10px;\n  margin-left: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.STQycHHdWWsLgUU_rtPiY {\n  margin: 0px;\n  flex: 1;\n  font-size: 18px;\n  font-weight: 600;\n}\n\n._1ekcd3VYfLkTbwr3yoIM63 {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.Ukqlt4P7OlQwQC1a4aMX5 {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/ProjectView/Components/TargetCosts/Components/GroupHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb","file":"GroupHeader.css","sourcesContent":[".wrapper {\n  height: 100%;\n  padding-top: 10px;\n  margin-left: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.name {\n  margin: 0px;\n  flex: 1;\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.headings {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.cell {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3cn3gnPRQ9bDgALM0llS4-",
	"name": "STQycHHdWWsLgUU_rtPiY",
	"headings": "_1ekcd3VYfLkTbwr3yoIM63",
	"cell": "Ukqlt4P7OlQwQC1a4aMX5"
};