exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._17MmKFT0T_9kZCr4Z0XG3U {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.NvmMIE0d4bPWP2xXMoBXZ {\n  flex: 1;\n  margin: 0px;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/OrderView/Components/Header/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;CACb","file":"Component.css","sourcesContent":[".stack {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.heading {\n  flex: 1;\n  margin: 0px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"stack": "_17MmKFT0T_9kZCr4Z0XG3U",
	"heading": "NvmMIE0d4bPWP2xXMoBXZ"
};