exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".LEST7BivcPrJ2OrBwDqc2 {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n._12xvwFeBInXV9audoaA3pJ {\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  cursor: pointer;\n}\n\n._2RocX9S6nPxakqU3ItasdE {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n._36zqhMqRbtZgTTtmCa0JiU {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubOrder/Components/Items/Components/SubOrderGroupFooter/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;CACpB;;AAED;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;EAC1B,gBAAgB;CACjB;;AAED;EACE,eAAe;EACf,aAAa;CACd;;AAED;EACE,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n.link {\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  cursor: pointer;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n.total {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "LEST7BivcPrJ2OrBwDqc2",
	"link": "_12xvwFeBInXV9audoaA3pJ",
	"addLink": "_2RocX9S6nPxakqU3ItasdE",
	"total": "_36zqhMqRbtZgTTtmCa0JiU"
};