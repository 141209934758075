import fetch from '../Services/fetch'
import fetchClients from '../../../Core/Services/fetchClients'
import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes'

export default () => (dispatch) => {
  
  dispatch(fetch())

  dispatch(fetchClients())
  dispatch(fetchServiceTypes())
  
}