exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._8wxMcZjzaicHmKa_IXSEl {\n  width: 100%;\n  height: 70px;\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  display: flex;\n  border-top: 1px solid #DDD;\n  background-color: white;\n  padding-left: 30px;\n  padding-right: 310px;\n  z-index: 1;\n  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);\n}\n\n._3KVT_AAFE6IwhbcWLxR4LE {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n._3-6lIuFRRPdQV8GHCN60I4 {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n._1diYy7eemHMF8be3gAEzMJ {\n  font-size: 14px;\n  font-weight: 600;\n  margin-left: 30px;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubInvoice/Components/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,cAAc;EACd,2BAA2B;EAC3B,wBAAwB;EACxB,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,+CAA+C;CAChD;;AAED;EACE,QAAQ;EACR,aAAa;EACb,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 70px;\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  display: flex;\n  border-top: 1px solid #DDD;\n  background-color: white;\n  padding-left: 30px;\n  padding-right: 310px;\n  z-index: 1;\n  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);\n}\n\n.buttons {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.totals {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.total {\n  font-size: 14px;\n  font-weight: 600;\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_8wxMcZjzaicHmKa_IXSEl",
	"buttons": "_3KVT_AAFE6IwhbcWLxR4LE",
	"totals": "_3-6lIuFRRPdQV8GHCN60I4",
	"total": "_1diYy7eemHMF8be3gAEzMJ"
};