import React from 'react'
import { Modal, Dropdown, Menu, Button } from 'antd'
import { DeleteOutlined, FrownOutlined, SafetyOutlined, SmileOutlined } from '@ant-design/icons'
import Form from './Form'

type Props = {
    id: ?any,
    type: string,
    group: string,
    processor: string,
    processor_json: string,
    value: string,
    title: string,
    validation_json: string,
    submitting: boolean,
    logo: string,
    resetPasswordModal: {},
    componentWillMount: (props: any) => void,
    onClickSubmit: () => void,
    onClickCancel: () => void,
    onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props)

  getOptionsMenu = () => {
    const {
      onClickDelete,
    } = this.props
    return (
      <Menu
        onClick={({ key }) => {
          if (key === 'delete') {
            onClickDelete()
          }
        }}
      >
        <Menu.Item key='delete'><DeleteOutlined />{` Delete`}</Menu.Item>
      </Menu>
    )
  }

  render() {

    const {
        id,
        type,
        group,
        processor,
        processor_json,
        value,
        title,
        validation_json,
        submitting,
        logo,
        onUpload,
        onClickSubmit,
        onClickCancel,
    } = this.props

    const footer = []

    /*if (id) {
      footer.push([
        <Dropdown key='options' overlay={this.getOptionsMenu()}>
          <Button style={{ float: 'left' }} loading={submitting}>
            Options
          </Button>
        </Dropdown>,
      ])
    }*/

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (

      <Modal
        title={'Edit Setting: ' + title}
        footer={footer}
        onCancel={onClickCancel}
        visible
      >

        <Form
          id={id}
          type={type}
          group={group}
          processor={processor}
          processor_json={processor_json}
          value={value}
          title={title}
          validation_json={validation_json}
          submitting={submitting}
          logo={logo}
          onUpload={onUpload}
        />

      </Modal>

    )

  }

}
