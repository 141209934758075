exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".EVoaGs99YTOtF6DEGpo4X {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n._1ibyZsaRYyje5WawntLzXR {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n._2p5BjPvndGEaTJ6TphSpm_ {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n._2p5BjPvndGEaTJ6TphSpm_:hover {\n  color: #1990FF;\n  opacity: 1;\n}\n\n._1RUcRBniypN7Wc05gE9o5L:hover {\n  color: #F5222D;\n}\n\n._1wQqk_s7JawdzxVCt25yXe {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/EstimateView/Components/Optionals/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;EACf,aAAa;CACd;;AAED;EACE,eAAe;EACf,WAAW;CACZ;;AAED;EACE,eAAe;CAChB;;AAED;EACE,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;CAClB","file":"Footer.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.link {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n.addLink:hover {\n  color: #1990FF;\n  opacity: 1;\n}\n\n.removeLink:hover {\n  color: #F5222D;\n}\n\n.total {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "EVoaGs99YTOtF6DEGpo4X",
	"link": "_1ibyZsaRYyje5WawntLzXR",
	"addLink": "_2p5BjPvndGEaTJ6TphSpm_",
	"removeLink": "_1RUcRBniypN7Wc05gE9o5L",
	"total": "_1wQqk_s7JawdzxVCt25yXe"
};