exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._13TXaUv6nEh-gg33Nb3uOy {\n  display: flex;\n  align-items: center;\n  margin-top: 8px;\n  margin-bottom: 8px;\n  cursor: pointer;\n}\n\n.R2wJKLg-tIpUlWt0EvQIv {\n  padding-left: 20px;\n  width: 100px;\n  font-weight: bold;\n  text-align: center;\n}\n\n._1AJeZ_u0gSI22QG1Wklr9I {\n  padding-left: 20px;\n  flex: 1;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Modals/SelectCaveats/Components/List/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;CACjB;;AAED;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;CACpB;;AAED;EACE,mBAAmB;EACnB,QAAQ;CACT","file":"Component.css","sourcesContent":[".caveatWrapper {\n  display: flex;\n  align-items: center;\n  margin-top: 8px;\n  margin-bottom: 8px;\n  cursor: pointer;\n}\n\n.caveatType {\n  padding-left: 20px;\n  width: 100px;\n  font-weight: bold;\n  text-align: center;\n}\n\n.caveatContent {\n  padding-left: 20px;\n  flex: 1;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"caveatWrapper": "_13TXaUv6nEh-gg33Nb3uOy",
	"caveatType": "R2wJKLg-tIpUlWt0EvQIv",
	"caveatContent": "_1AJeZ_u0gSI22QG1Wklr9I"
};