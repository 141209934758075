exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3_wkoQta1cHkAqsygmY0su {\n\n}\n\n._3UcgBqQ863VMDtGqi_DdRu {\n  \n}\n\n._36VBMidfxfGNsIcXD-25G2 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._2EVGimKtnVMEFgb4O3l3zd {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/RamsRecords/Components/Header/Component.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;;CAEC;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;CACd","file":"Component.css","sourcesContent":[".wrapper {\n\n}\n\n.breadcrumbs {\n  \n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.title {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3_wkoQta1cHkAqsygmY0su",
	"breadcrumbs": "_3UcgBqQ863VMDtGqi_DdRu",
	"header": "_36VBMidfxfGNsIcXD-25G2",
	"title": "_2EVGimKtnVMEFgb4O3l3zd"
};