exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._5pEWybkblCmb4P1AYaAx- {\n  border: 1px solid #E8E8E8;\n  background-color: white;\n}\n\n._1ObWfFhqTeezsnXuSTa3cD {\n  height: 700px;\n  display: flex;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Modules/FileManager/styles.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,wBAAwB;CACzB;;AAED;EACE,cAAc;EACd,cAAc;CACf","file":"styles.css","sourcesContent":[".wrapper {\n  border: 1px solid #E8E8E8;\n  background-color: white;\n}\n\n.body {\n  height: 700px;\n  display: flex;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_5pEWybkblCmb4P1AYaAx-",
	"body": "_1ObWfFhqTeezsnXuSTa3cD"
};