import React from "react";
import { Switch, Route } from "react-router-dom";
import { isSuper, isPremium, isAdvanced, isAdmin, isBasicPlus, isBasic } from 'Common'

import Home from "./Screens/Home";

import Dashboard from "./Screens/Dashboard";

import Estimates from "./Screens/Estimates";
import Caveats from "./Screens/Caveats";
import EstimateView from "./Screens/EstimateView";

import Clients from "./Screens/Clients";
import ClientView from "./Screens/ClientView";

import Orders from "./Screens/Orders";
import OrderView from "./Screens/OrderView";

import Projects from "./Screens/Projects";
import ProjectView from "./Screens/ProjectView";

import Valuations from "./Screens/Valuations";
import ValuationView from "./Screens/ValuationView";

import Invoices from "./Screens/Invoices";
import InvoiceView from "./Screens/InvoiceView";

import RetentionPayments from "./Screens/RetentionPayments";

import RamsRecords from "./Screens/RamsRecords";

import Subbies from "./Screens/Subbies";
import SubcontractorView from "./Screens/SubcontractorView";

import Planner from "./Screens/Planner";

import TimesheetPosting from "./Screens/TimesheetPosting";
import Timesheets from "./Screens/Timesheets";

import ProfitAndLoss from "./Screens/ProfitAndLoss";

import Insights from "./Screens/Insights";

import SubProjects from "./Screens/SubProjects";
import SubProject from "./Screens/SubProject";

import SubOrders from "./Screens/SubOrders";
import SubOrder from "./Screens/SubOrder";

import SubValuations from "./Screens/SubValuations";
import SubValuation from "./Screens/SubValuation";

import SubInvoice from "./Screens/SubInvoice";

import Users from "./Screens/Users";
import Settings from "./Screens/Settings";

export default () => (
  <Switch>
    {/* Home */}
    <Route path="/app/teams/:slug" exact component={Home} />

    {/* Router for Projects */}
      {isSuper() && <Route path="/app/teams/:slug/projects" exact component={Projects} />}
      {isSuper() && <Route path="/app/teams/:slug/projects/:id/view" exact component={ProjectView} />}
      {isAdvanced() && <Route path="/app/teams/:slug/projects" exact component={Projects} />}
      {isAdvanced() && <Route path="/app/teams/:slug/projects/:id/view" exact component={ProjectView} />}

    {/* Router for Estimates */}
    {isBasic() && <Route path="/app/teams/:slug/estimates" exact component={Estimates} />}
    {isAdmin() && <Route path="/app/teams/:slug/estimates/caveats" exact component={Caveats} />}
    {isBasic() && <Route path="/app/teams/:slug/estimates/:id/view" exact component={EstimateView} />}

    {/* Router for Orders */}
    {isBasic() && <Route path="/app/teams/:slug/orders" exact component={Orders} />}
    {isBasic() && <Route path="/app/teams/:slug/orders/:id/view" exact component={OrderView} />}

    {/* Router for Valuations */}
    {isBasic() && <Route path="/app/teams/:slug/valuations" exact component={Valuations} />}
    {isBasic() && <Route path="/app/teams/:slug/valuations/:id/view" exact component={ValuationView} />}

    {/* Router for Invoices */}
    {isBasic() && <Route path="/app/teams/:slug/invoices" exact component={Invoices} />}
    {isBasic() && <Route path="/app/teams/:slug/invoices/:id/view" exact component={InvoiceView} />}

    {/* Router for Retention Payments */}
      {isSuper() && <Route path="/app/teams/:slug/retention_payments" exact component={RetentionPayments} />}
      {isAdvanced() && <Route path="/app/teams/:slug/retention_payments" exact component={RetentionPayments} />}

    {/* Router for Rams Records */}
      {isSuper() && <Route path="/app/teams/:slug/rams_records" exact component={RamsRecords} />}
      {isPremium() && <Route path="/app/teams/:slug/rams_records" exact component={RamsRecords} />}

    {/* Router for Clients */}
    {isBasic() && <Route path="/app/teams/:slug/clients" exact component={Clients} />}
    {isBasic() && <Route path="/app/teams/:slug/clients/:id/view" exact component={ClientView} />}

    {/** Router for Users */}
    {isAdmin() && <Route path="/app/teams/:slug/users" exact component={Users} />}

    {/** Router for system Settings */}
    {isBasicPlus() && <Route path="/app/teams/:slug/settings" exact component={Settings} />}

    {/* Router for Subcontractors */}
      {isSuper() && <Route path="/app/teams/:slug/subcontractors" exact component={Subbies} />}
      {isSuper() && <Route path="/app/teams/:slug/subcontractors/:id/view" exact component={SubcontractorView} />}
      {isAdvanced() && <Route path="/app/teams/:slug/subcontractors" exact component={Subbies} />}
      {isAdvanced() && <Route path="/app/teams/:slug/subcontractors/:id/view" exact component={SubcontractorView} />}

    {/* Router for Planner */}
      {isSuper() && <Route path="/app/teams/:slug/planner" exact component={Planner} />}
      {isPremium() && <Route path="/app/teams/:slug/planner" exact component={Planner} />}

    {/* Router for Timesheets */}
      {isSuper() && <Route path="/app/teams/:slug/timesheets" exact component={Timesheets} />}
      {isAdvanced() && <Route path="/app/teams/:slug/timesheets" exact component={Timesheets} />}

      {/* Router for Profit & Loss */}
      {isSuper() && <Route path="/app/teams/:slug/profit_and_loss" exact component={ProfitAndLoss} />}
      {isAdvanced() && <Route path="/app/teams/:slug/profit_and_loss" exact component={ProfitAndLoss} />}

    {/* Router for Insights */}
      {isSuper() && <Route path="/app/teams/:slug/insights" exact component={Insights} />}
      {isAdvanced() && <Route path="/app/teams/:slug/insights" exact component={Insights} />}

    {/* Router for Sub Projects */}
      {isSuper() && <Route path="/app/teams/:slug/sub_projects" exact component={SubProjects} />}
      {isSuper() && <Route path="/app/teams/:slug/sub_projects/:id" exact component={SubProject} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_projects" exact component={SubProjects} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_projects/:id" exact component={SubProject} />}

    {/* Router for Sub Orders */}
      {isSuper() && <Route path="/app/teams/:slug/sub_orders" exact component={SubOrders} />}
      {isSuper() && <Route path="/app/teams/:slug/sub_orders/:id" exact component={SubOrder} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_orders" exact component={SubOrders} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_orders/:id" exact component={SubOrder} />}

    {/* Router for Sub Valuations */}
      {isSuper() && <Route path="/app/teams/:slug/sub_valuations" exact component={SubValuations} />}
      {isSuper() && <Route path="/app/teams/:slug/sub_valuations/:id" exact component={SubValuation} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_valuations" exact component={SubValuations} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_valuations/:id" exact component={SubValuation} />}

    {/* Router for Sub Invoices */}
      {isSuper() && <Route path="/app/teams/:slug/sub_invoices/:id" exact component={SubInvoice} />}
      {isAdvanced() && <Route path="/app/teams/:slug/sub_invoices/:id" exact component={SubInvoice} />}

    {/* Router for Dashboard */}
      {isSuper() && <Route path="/app/teams/:slug/dashboard" exact component={Dashboard} />}
      {isAdvanced() && <Route path="/app/teams/:slug/dashboard" exact component={Dashboard} />}

  </Switch>
);
