exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".g7pzku4iRnV6I24lcf3Zo {\n  display: flex;\n  align-items: flex-start;\n  padding-top: 0px !important;\n  padding-bottom: 0px !important;\n}\n\n.g7pzku4iRnV6I24lcf3Zo .ant-list-item-content {\n  flex: none;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Modals/Timesheet/Components/Form.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,wBAAwB;EACxB,4BAA4B;EAC5B,+BAA+B;CAChC;;AAED;EACE,WAAW;CACZ","file":"Form.css","sourcesContent":[".listItem {\n  display: flex;\n  align-items: flex-start;\n  padding-top: 0px !important;\n  padding-bottom: 0px !important;\n}\n\n.listItem :global(.ant-list-item-content) {\n  flex: none;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"listItem": "g7pzku4iRnV6I24lcf3Zo"
};