import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'
import { FORM_NAME } from '../../Form'
import Component from './Component'

import onBeforeUpload from './Handlers/onBeforeUpload'
import onChangeFiles from './Handlers/onChangeFiles'

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes,
  files: state.modals.RamsRecord.files,
  submitting: isSubmitting(FORM_NAME)(state),
})

const actionCreators = {
  onBeforeUpload,
  onChangeFiles,
}

export default connect(mapStateToProps, actionCreators)(Component)