import React from 'react'
import { Field } from 'redux-form'
import { Tag } from 'antd'
import { InputField, SelectField } from 'FormFields'
import { isPremium, isAdvanced, isPaymentUser } from 'Common';


const labelColSpan = 6
const wrapperColSpan = 14

type Props = {
  subcontractors: [],
  id: ?any,
  type: string,
  submitting: boolean,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      subcontractors,
      id,
      type,
      submitting,
    } = this.props

    return (

      <div>

        <Field
          component={InputField}
          name='first_name'
          label='First Name'
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          component={InputField}
          name='last_name'
          label='Last Name'
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />


          { (isPaymentUser()) && <Field
              component={SelectField}
              name='type'
              label='Type'
              options={[
                  { label: <Tag color='grey'>Premium</Tag>, value: 'Premium' },
                  { label: <Tag color='orange'>Advanced</Tag>, value: 'Advanced' },
                  { label: <Tag color='green'>Admin</Tag>, value: 'Admin' },
                  { label: <Tag>Basic</Tag>, value: 'Basic' },
              ]}
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
          />
          }

          { (!isPaymentUser()) && <Field
              component={SelectField}
              name='type'
              label='Type'
              options={[
                  { label: <Tag color='grey'>Premium</Tag>, value: 'Premium' },
                  { label: <Tag color='orange'>Advanced</Tag>, value: 'Advanced' },
                  { label: <Tag color='green'>Admin</Tag>, value: 'Admin' },
                  { label: <Tag>Basic</Tag>, value: 'Basic' },
              ]}
              disabled={true}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
          />
          }

        {type === 'Subcontractor' && (

          <Field
            component={SelectField}
            name='subcontractor_id'
            label='Subcontractor'
            options={subcontractors.map(subcontractor => ({
              label: subcontractor.name,
              value: subcontractor.id,
            }))}
            dropdownMatchSelectWidth={false}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

        )}

        <Field
          component={InputField}
          name='email'
          label='Email'
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        {!id && (

          <React.Fragment>

            <Field
              component={InputField}
              name='password'
              label='Password'
              type='password'
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
            />

            <Field
              component={InputField}
              name='password_confirm'
              label='Confirm'
              type='password'
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
            />

          </React.Fragment>

        )}

        {id && (

          <Field
            component={InputField}
            name='status'
            label='Status'
            options={[
              { label: 'Active', value: 'Active' },
              { label: 'Deactivated', value: 'Deactivated' },
            ]}
            disabled
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

        )}

      </div>

    )

  }

}
