import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'
import Header from "./Components/Header"
import Filters from "./Components/Filters"
import Table from "./Components/Table"
import RamsRecordModal from '../../Modals/RamsRecord'

export default class _RamsRecords_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    ramsRecordModal: PropTypes.object.isRequired,
    onCloseRamsRecordModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    const {
      ramsRecordModal,
      onCloseRamsRecordModal,
    } = this.props

    return (

      <Screen>
	    <div class="_coming_soon">
            	<h1>Coming soon</h1>
	      	<p>Fully automated RAMS, including:</p>
                <ul>
                    <li>Digital signed RAMS</li>
                    <li>Automated reminders and triggers</li>
                    <li>Full compliance and audit trail</li>
                    <li>Reporting and statistics</li>
                </ul>
            </div>


          {/*<Header />

        <Filters />

        <Table />

        {ramsRecordModal.show && (
          <RamsRecordModal
            ramsRecordId={ramsRecordModal.ramsRecordId}
            onClose={onCloseRamsRecordModal}
          />
        )}
	    */}
      </Screen>

    )
  }
}
