exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3GDYbQiGK5Nddc4DT28Xl5 {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  margin-top: 10px;\n  border-top: 1px solid #DDD;\n  padding-top: 20px;\n  display: flex;\n  flex-direction: row;\n}\n\n.uuoPZfliGm_4ou7tHB3uZ {\n  flex: 1;\n}\n\n._34-UOVLCY04r9NNmERzwlD {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #1990FF;\n  font-weight: 500;\n  font-size: 14px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n._2F22GbguBF8sVAcDb1lbqg {\n  color: #1990FF;\n  opacity: 0.9;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/OrderView/Components/Items/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,2BAA2B;EAC3B,kBAAkB;EAClB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;EACf,aAAa;CACd","file":"Footer.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  margin-top: 10px;\n  border-top: 1px solid #DDD;\n  padding-top: 20px;\n  display: flex;\n  flex-direction: row;\n}\n\n.buttons {\n  flex: 1;\n}\n\n.link {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #1990FF;\n  font-weight: 500;\n  font-size: 14px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3GDYbQiGK5Nddc4DT28Xl5",
	"buttons": "uuoPZfliGm_4ou7tHB3uZ",
	"link": "_34-UOVLCY04r9NNmERzwlD",
	"addLink": "_2F22GbguBF8sVAcDb1lbqg"
};