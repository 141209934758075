import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Input, Select, InputNumber, DatePicker, Switch, Spin, Button, Divider, Tag, Tooltip } from 'antd'
import { CheckOutlined, DeleteOutlined, PoundOutlined } from '@ant-design/icons'
import moment from 'moment'
import getConflictingJobs from './Utils/getConflictingJobs'
import styles from './styles.css'

import SubcontractorIcon from './Components/SubcontractorIcon'

const Spacer = () => <div style={{ width: 10 }} />

export default class ProjectCellModal extends React.PureComponent {

  // Accounting for deactivated subbies in subbie drop-down

  static propTypes = {
    period: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    subcontractors: PropTypes.array.isRequired,
    jobs: PropTypes.array.isRequired,
    plannerCell: PropTypes.object.isRequired,
    duplicate: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onAddJob: PropTypes.func.isRequired,
    onUpdateJob: PropTypes.func.isRequired,
    onRemoveJob: PropTypes.func.isRequired,
    onUpdatePlannerCell: PropTypes.func.isRequired,
    onChangeDuplicateKey: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      period,
      project,
      activeTab,
      subcontractors,
      jobs,
      plannerCell,
      duplicate,
      onChangeActiveTab,
      onAddJob,
      onUpdateJob,
      onRemoveJob,
      onUpdatePlannerCell,
      onChangeDuplicateKey,
      onSave,
      onClose,
    } = this.props

    const footer = [
      <Button
        key='cancel'
        onClick={() => onClose()}
      >
        Cancel
      </Button>,
      <Button
        key='save'
        type='primary'
        onClick={onSave}
      >
        Save
      </Button>,
    ]

    const jobsInSlot = jobs.filter(job => job.slot === activeTab)

    const modalProps = {
      title: `${period.day_of_week} ${period.date_formatted}`,
      visible: true,
      width: 600,
      footer,
      onCancel: () => onClose(),
      bodyStyle: { padding: '24px 24px 13px 24px' },
    }

    if (!subcontractors.length) return (
      <Modal {...modalProps}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      </Modal>
    )

    return (

      <Modal {...modalProps}>
        
        <div className={styles.wrapper}>

          <div style={{ fontSize: 15 }}>
            <div style={{ fontWeight: 600 }}>Project {project.number}</div>
            <div>{project.client_site.client.name}</div>
            <div style={{ color: '#9e9e9e' }}>{project.client_site.name}</div>
          </div>

          <br />

          <Tabs activeKey={activeTab} onChange={onChangeActiveTab} size='small' style={{ marginBottom: 8 }}>
            <Tabs.TabPane key='Day' tab='Day' />
            <Tabs.TabPane key='AM' tab='AM' />
            <Tabs.TabPane key='PM' tab='PM' />
            <Tabs.TabPane key='Night' tab='Night' />
            <Tabs.TabPane key='Alert' tab='Alert' />
          </Tabs>

          {activeTab !== 'Alert' && (

            <React.Fragment>

              {jobsInSlot.map((job, index) => (

                <div key={job.key} className={styles.subcontractor}>

                  <div style={{ width: 16 }}>{index + 1}</div>

                  <Spacer />

                  <Select
                    value={job.subcontractor_id}
                    onChange={value => onUpdateJob(job.key, 'subcontractor_id', value)}
                    showSearch
                    optionFilterProp='label'
                    style={{ flex: 1 }}
                  >
                    {subcontractors.map(subcontractor => {
                      const conflictingJobs = getConflictingJobs(subcontractor.jobs, job)
                      return (
                        <Select.Option key={subcontractor.id} label={subcontractor.name} value={subcontractor.id}>
                          <span
                            style={{
                              color: (() => {
                                if (subcontractor.confirmed_slots < subcontractor.slots) return '#ff0000'
                                if (!subcontractor.available || subcontractor.absence) return '#9e9e9e'
                                return undefined
                              })(),
                              textDecoration: !subcontractor.is_multiple && !!conflictingJobs.length && 'line-through',
                            }}
                          >
                            <SubcontractorIcon subcontractor={subcontractor} />
                            {subcontractor.name}
                            {!!subcontractor.is_supervisor && <Tag color='volcano' style={{ marginLeft: 8 }}>Supervisor</Tag>}
                            {!!subcontractor.is_trainee && <Tag color='cyan' style={{ marginLeft: 8 }}>Trainee</Tag>}
                          </span>
                        </Select.Option>
                      )
                    })}
                  </Select>

                  {!!job.subcontractor.is_multiple && (

                    <React.Fragment>

                      <Spacer />

                      <InputNumber
                        value={job.quantity}
                        onChange={value => onUpdateJob(job.key, 'quantity', value)}
                        style={{ width: 60 }}
                      />

                    </React.Fragment>

                  )}

                  <Spacer />

                  <Tooltip title={job.price_work ? 'Unmark as Price Work' : 'Mark as Price Work'}>
                    <Button
                      type={job.price_work ? 'primary' : 'default'}
                      icon={<PoundOutlined />}
                      onClick={() => onUpdateJob(job.key, 'price_work', !job.price_work)}
                    />
                  </Tooltip>

                  <Spacer />

                  <Tooltip title={job.confirmed ? 'Unconfirm' : 'Confirm'}>
                    <Button
                      type={job.confirmed ? 'primary' : 'default'}
                      icon={<CheckOutlined />}
                      onClick={() => onUpdateJob(job.key, 'confirmed', !job.confirmed)}
                    />
                  </Tooltip>

                  <Spacer />

                  <Tooltip title='Remove'>
                    <Button type='danger' icon={<DeleteOutlined />} onClick={() => onRemoveJob(job.key)} />
                  </Tooltip>

                </div>

              ))}

              {!!jobsInSlot.length && (

                <div className={styles.addSubcontractor}>

                  <div style={{ width: 26 }} />

                  <a
                    role='link'
                    tabIndex={0}
                    onClick={onAddJob}
                    style={{ fontSize: 13, fontStyle: 'italic' }}
                  >
                    + Add subcontractor
                  </a>

                </div>

              )}

              {!jobsInSlot.length && (

                <div>No subcontractors scheduled for this slot. Click <a role='link' tabIndex={0} onClick={onAddJob}>here</a> to add.</div>

              )}

            </React.Fragment>
            
          )}

          {activeTab === 'Alert' && (

            <Input.TextArea
              value={plannerCell.alert}
              onChange={e => onUpdatePlannerCell('alert', e.target.value)}
              rows={4}
              placeholder='Insert alert here'
            />

          )}

          <Divider style={{ marginTop: activeTab !== 'Alert' && !!jobsInSlot.length && 16 }} />

          <div className={styles.inputRow}>

            <div>Subcontractors required:</div>

            <InputNumber
              value={plannerCell.required}
              onChange={value => onUpdatePlannerCell('required', value)}
              style={{ width: 135 }}
            />

          </div>

          <div className={styles.inputRow}>

            <div>Duplicate cell up until:</div>

            <DatePicker
              value={duplicate.upToDate}
              onChange={value => onChangeDuplicateKey('upToDate', value)}
              disabledDate={date => date < moment(period.date)}
              style={{ width: 135 }}
            />

          </div>

          <div className={styles.inputRow}>

            <div>Include weekends:</div>

            <Switch
              checked={duplicate.incWeekends}
              onChange={value => onChangeDuplicateKey('incWeekends', value)}
            />

          </div>

        </div>

      </Modal>

    )

  }

}
