/* eslint-disable no-nested-ternary */
import { initialize } from 'redux-form'
import { findSetting } from '../../../HttpRequests/settings'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'

export default () => async (dispatch) => {

  const { id } = getProps()

  const params = {
    include: 'group',
    append: 'status',
  }

  if (id) {

    const response = await findSetting({ id, params })
    const setting = response.data
    
    dispatch(initialize(FORM_NAME, {
      ...setting,
    }))

  } else {

    dispatch(initialize(FORM_NAME, {
      //
    }))

  }

}
