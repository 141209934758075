exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".urkskbfiDCxJ88CsVP7BL {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n._1_WwhS7uUFl2aNT-jSqPe9 {\n  justify-content: flex-end;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/Subbies/Components/SubProjects/Components/Summary/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;CACjB;;AAED;EACE,0BAA0B;CAC3B","file":"styles.css","sourcesContent":[".column {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.numeric {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"column": "urkskbfiDCxJ88CsVP7BL",
	"numeric": "_1_WwhS7uUFl2aNT-jSqPe9"
};