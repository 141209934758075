exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1rdsp2d6Snp8SsbSrD7GrA {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  padding-top: 20px;\n  margin-left: 30px;\n  margin-right: 65px;\n  margin-top: 10px;\n}\n\n._2JR82JU95EVvbfjUVCgIqT {\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  cursor: pointer;\n}\n\n._3VkLEGFxJRHUhrm-Ylk9lm {\n  color: #1990FF;\n  opacity: 0.9;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubOrder/Components/Items/Components/SubOrderFooter/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;CAClB;;AAED;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;EAC1B,gBAAgB;CACjB;;AAED;EACE,eAAe;EACf,aAAa;CACd","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  padding-top: 20px;\n  margin-left: 30px;\n  margin-right: 65px;\n  margin-top: 10px;\n}\n\n.link {\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  cursor: pointer;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1rdsp2d6Snp8SsbSrD7GrA",
	"link": "_2JR82JU95EVvbfjUVCgIqT",
	"addLink": "_3VkLEGFxJRHUhrm-Ylk9lm"
};