exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2_ll23FZvN-3v279R6dCSy {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._30bVd9Z-xWrYJjODRH70XB {\n  flex: 1;\n  display: flex;\n}\n\n._2RX3c_ibJz7FfZV3dM46fg {\n  display: flex;\n}\n\n._2ygt0ehB_1mzMaR-upJcQx {\n  width: 280px;\n}\n\n._1_V1uTdVxQMHcjQGv-WJvr {\n  margin-bottom: 12px;\n}\n\n._1_V1uTdVxQMHcjQGv-WJvr:last-child {\n  margin-bottom: 4px;\n}\n\n._6bD86Cn38hsiXi_rrKhJD {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n\n._2GRTb95KrzeOpmVv18GkLL {\n  min-width: 80px;\n  text-align: center;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/Subbies/Components/SubProjects/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;EACE,cAAc;CACf;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n  display: flex;\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n\n.radioButton {\n  min-width: 80px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "_2_ll23FZvN-3v279R6dCSy",
	"left": "_30bVd9Z-xWrYJjODRH70XB",
	"right": "_2RX3c_ibJz7FfZV3dM46fg",
	"filters": "_2ygt0ehB_1mzMaR-upJcQx",
	"box": "_1_V1uTdVxQMHcjQGv-WJvr",
	"label": "_6bD86Cn38hsiXi_rrKhJD",
	"radioButton": "_2GRTb95KrzeOpmVv18GkLL"
};