exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1sQrv1wUaByUgxdavYHUEv {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n._3d6Ptl-53ieo7fVSDKsAEO {\n  flex: 1;\n}\n\n._3oR6JKqjAm5pS0tkdEeGP5 {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.H6AXOS1g39X_Zwv_wyrlR {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/EstimateView/Components/Caveats/Components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb","file":"Header.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.spacer {\n  flex: 1;\n}\n\n.headings {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.cell {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1sQrv1wUaByUgxdavYHUEv",
	"spacer": "_3d6Ptl-53ieo7fVSDKsAEO",
	"headings": "_3oR6JKqjAm5pS0tkdEeGP5",
	"cell": "H6AXOS1g39X_Zwv_wyrlR"
};