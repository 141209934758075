// @flow
import * as React from 'react';
import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';

type Props = {
  dispatch: Function
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => {
    this.props.dispatch(push(getTeamUrl('estimates')))
  };

  render = () => <div />
  
}