exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".f0Tv6_oO5OlYnXYskojBR {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #DDD;\n  margin-left: 30px;\n}\n\n.Eu_ALjoRqlbMAgjr8HE4_ {\n  flex: 1;\n  font-size: 18px;\n  font-weight: 600;\n}\n", "", {"version":3,"sources":["/home/forge/leadpm.io/frontend/src/Screens/SubInvoice/Components/Items/Components/SubValuationFooter/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,8BAA8B;EAC9B,kBAAkB;CACnB;;AAED;EACE,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #DDD;\n  margin-left: 30px;\n}\n\n.total {\n  flex: 1;\n  font-size: 18px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "f0Tv6_oO5OlYnXYskojBR",
	"total": "Eu_ALjoRqlbMAgjr8HE4_"
};